import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Colors from '../shared/Colors'
import logo from '../assests/logo-white.png'
import jmfoundation from '../assests/jmfoundation-white.png'
import jamraock from '../assests/JAMROCKnc-whte.png'
import jmcoffee from '../assests/jmcoffee.PNG'
import moment from 'moment'
import ExpandMoreIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
// import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { FaTiktok } from 'react-icons/fa'
import m_v_logo from '../assests/visa-and-mastercard-logo.png'


const Footer = () => {
  return (
    <div style={{ backgroundColor: '#000', paddingTop: 10, paddingBottom: 30 }}>


      <Container style={{ marginTop: 10, textAlign: 'center' }}>
        <div >
          <a href="tel:" style={{ textDecoration: 'none', color: '#fff' }}>
            <p style={{ marginBottom: 0, textAlign: 'center', fontSize: '2em' }}>+1 (876) 368-9597</p>
          </a>
          <p style={{ textAlign: 'center', color: '#fff', fontWeight: '700', marginBottom: 0, }}>OPENS 8AM - 5PM EVERYDAY</p>


          <div className="social-media">

            <a href="https://www.facebook.com/search/top?q=rad%20venture" target="_blank" rel="noopener noreferrer">
              <FacebookIcon />
            </a>

            <a href="https://www.youtube.com/@radventure6502" target="_blank" rel="noopener noreferrer">
              <YouTubeIcon />
            </a>
            <a href="https://www.instagram.com/radventure.co/" target="_blank" rel="noopener noreferrer">
              <InstagramIcon />
            </a>
            <a href="https://www.tiktok.com/@radventure.co" target="_blank" rel="noopener noreferrer">
              <FaTiktok color={'white'} />
            </a>
          </div>


        </div>
        {/* <div>
          <p style={{ textAlign: 'center', textTransform: 'uppercase', fontWeight: '600', color: '#fff' }}>Part of our family</p>
          <Row>
            <Col md={3}></Col>
            <Col md={2}>
              <img src={jamraock} style={{ height: 60, textAlign: 'center' }} />
            </Col>
            <Col md={2}>
              <img src={jmfoundation} style={{ height: 70, textAlign: 'center' }} />
            </Col>
            <Col md={2}>
              <img src={jmcoffee} style={{ height: 70, textAlign: 'center' }} />
            </Col>
            <Col md={3}></Col>
          </Row>
        </div> */}

        <Row>
          <Col md={6}>
            {/* <div>
              <img src={m_v_logo} style={{ width: '40%' }} />
            </div> */}
          </Col>
          <Col md={6}>
            <div className='footer-links'>
              <a href="/terms">Terms &amp; Condition</a>
              <span>|</span>
              <a href="/privacy">Privacy Policy</a>
              <span>|</span>
              <a href="/refund">Return Policy</a>
            </div>
          </Col>
        </Row>



        <p style={{ textAlign: 'left', color: '#fff', fontSize: '0.9em' }}><ExpandMoreIcon /> <span style={{ fontWeight: '700' }}>Ocho Rios, Jamaica</span> <b>&copy; {moment().format('yyyy').toString()} |</b> All Rights Reserved By <a href='/' style={{ textDecoration: 'none', color: '#fff', fontWeight: '700' }}>Rad Venture Limited</a></p>
      </Container>
    </div>
  )
}

export default Footer
