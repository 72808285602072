import React from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import logo from '../assests/logo.png'
import jamrock from '../assests/Jamrock-Skateboards-Logo-711b.png'
import Colors from '../shared/Colors'
import { FiHeart } from "react-icons/fi";
import { FaSearch, FaShoppingBag } from "react-icons/fa"
import { FiShoppingBag, FiGlobe } from "react-icons/fi"
import { useDispatch, useSelector } from 'react-redux';
import { setLoginShow, setLogoutShow, setJoinUsShow } from '../redux/actions';
import moment from 'moment/moment';


const Header = () => {

  const dispatch = useDispatch()

  const isAuth = useSelector(state => state.isAuth)

  const length = useSelector(state => state.cartItems.length)

 const path = window.location.pathname.substring(50, 0)

// console.log('Date header: ', moment(1734712943*1000).toDate())
  return (<>

    <Navbar expand="lg" className="justify-content-end" style={{ backgroundColor: '#f5f5f5', position: 'fixed', flex: 1, width: '100%', zIndex: 99, padding: '0 40px' }}>
      {/* <a href="/store" style={{ position: 'absolute', left: 30 }}><img src={jamrock} style={{ width: 30, marginTop: -10 }} /></a> */}
      {/* <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ color: '#fff' }} /> */}
      <Navbar id="basic-navbar-nav" className="justify-content-end" style={{ color: '#fff' }}>
        <Nav className="justify-content-end">
          {/* <Nav.Link href="/excursions" className='nav-link1'><FiGlobe size={15}  style={{marginBottom:3, marginRight:3}} />EN</Nav.Link>
          <Nav.Item style={{ marginTop: 5, marginRight: 10, marginLeft: 10 }}>|</Nav.Item> */}
          <Nav.Link href="/find-store" className='nav-link1'>Find a Store</Nav.Link>
          <Nav.Item style={{ marginTop: 5, marginRight: 10, marginLeft: 10 }}>|</Nav.Item>
          <Nav.Link href='/help' className='nav-link1'>Help</Nav.Link>
          <Nav.Item style={{ marginTop: 5, marginRight: 10, marginLeft: 10 }}>|</Nav.Item>
          {isAuth ? <Nav.Link onClick={() => dispatch(setLogoutShow(true))} className='nav-link1'>Sign Out</Nav.Link> : <Nav.Link onClick={() => dispatch(setJoinUsShow(true))} className='nav-link1'>Join Us</Nav.Link>}
          <Nav.Item style={{ marginTop: 5, marginRight: 10, marginLeft: 10 }}>|</Nav.Item>
          {isAuth ?
            <Nav.Link href='/account' className='nav-link1'>Account</Nav.Link>
            :
            <Nav.Link onClick={() => dispatch(setLoginShow(true))} className='nav-link1'>Sign In</Nav.Link>
          }
        </Nav>
      </Navbar>
    </Navbar>
    <Navbar expand="lg" className="justify-content-center" style={{ backgroundColor: '#fff', position: 'fixed', width: '100%', zIndex: 99, marginTop: 40, padding: '0 40px' }}>

      <Navbar.Brand href="/" className="justify-content-left"><img src={logo} style={{ width: 70 }} /></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ color: '#000' }} />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center" style={{ marginLeft: '27vw' }}>
        <Nav className="justify-content-center">
          <Nav.Link href="/excursions" className='Link ' style={{ borderBottom: path === '/excursions' && '3px solid #000'}}>Adventures</Nav.Link>
          <Nav.Link href="/store" className='Link ' style={{ borderBottom: path === '/store' && '3px solid #000'}}>Store</Nav.Link>
          <Nav.Link href="/foundation" className='Link ' style={{ borderBottom: path === '/foundation' && '3px solid #000'}}>Foundation</Nav.Link>
          <Nav.Link href="/contact" className='Link ' style={{ borderBottom: path === '/contact' && '3px solid #000'}}>Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end" style={{ color: '#000' }}>
        <Nav className="justify-content-end">
          <div style={{ borderRadius: 30, border: '1px solid silver', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', flexDirection: 'row', height: 35 }}>
            <FaSearch size={20} color={'gray'} style={{ marginLeft: 10, marginRight: 5 }} />
            <input placeholder='Search'  style={{ border: 'none', height: 35, color: 'gray' }} />
          </div>

          <Nav.Link href="/privacy" className='Link'><FiHeart size={20} color={'gray'} style={{ marginBottom: 5 }} /></Nav.Link>

          {
            isAuth ?
              <Nav.Link href="/cart" className='Link'>
                <FiShoppingBag size={20} color={'gray'} style={{ marginBottom: 5 }} />
                {isAuth && length > 0 &&
                  <span style={{ backgroundColor: Colors.secondary, marginLeft: -6, marginTop: -5, width: 16, height: 16, position: 'absolute', textAlign: 'center', borderRadius: 30, fontSize: 12 }}>{length}</span>
                }
              </Nav.Link> :
              <Nav.Link onClick={() => dispatch(setLoginShow(true))} className='Link'>
                <FiShoppingBag size={20} color={'gray'} style={{ marginBottom: 5 }} />
                {isAuth && length > 0 &&
                  <span style={{ backgroundColor: Colors.secondary, marginLeft: -6, marginTop: -5, width: 16, height: 16, position: 'absolute', textAlign: 'center', borderRadius: 30, fontSize: 12 }}>{length}</span>
                }
              </Nav.Link>
          }



        </Nav>


      </Navbar.Collapse>

    </Navbar>

  </>)
}

export default Header
