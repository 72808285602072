import Home from '../views/Home';
import SingleExcursion from '../views/SingleExcursion';
import Excursions from '../views/Excursions';
import SingleProduct from '../views/SingleProduct';
import Shop from '../views/Shop';
import { createBrowserRouter } from "react-router-dom";
import Privacy from '../views/Privacy';
import Carts from '../views/Carts';
import Foundation from '../views/Foundation';
import Help from '../views/Help';
import FindStore from '../views/FindStore';
import ThanksYou from '../views/ThanksYou';
import Refund from '../views/Refund';
import Cancellation from '../views/Cancellation';
import Account from '../views/Account';
import ShippingAddresses from '../views/ShippingAddresses';
import Orders from '../views/Orders';
import Bookings from '../views/Bookings';
import Terms from '../views/Terms';
import ViewPhotos from '../views/ViewPhotos';
import NotFound from '../views/NotFound';
import Contact from '../views/Contact';


export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: '/privacy',
    element: <Privacy />
  },
  {
    path: '/excursion/:id',
    element: <SingleExcursion />
  },
  {
    path: '/excursion/*',
    element: <SingleExcursion />
  },
  {
    path: '/excursions',
    element: <Excursions />
  },
  {
    path: '/product/:id',
    element: <SingleProduct />
  },
  {
    path: '/store',
    element: <Shop />
  },
  {
    path: '/cart',
    element: <Carts />
  },
  {
    path: '/foundation',
    element: <Foundation />
  },
  {
    path: '/help',
    element: <Help />
  },
  {
    path: '/find-store',
    element: <FindStore />
  },
  {
    path: '/thank-you',
    element: <ThanksYou />
  },
  {
    path: '/refund',
    element: <Refund />
  },
  {
    path: '/cancellation',
    element: <Cancellation />
  },
  {
    path: '/account',
    element: <Account />
  },
  {
    path: 'shipping-addresses',
    element: <ShippingAddresses />
  },
  {
    path: 'my-orders',
    element: <Orders />
  },
  {
    path:'my-bookings',
    element: <Bookings />
  },
  {
    path:'/terms',
    element:<Terms />
  },
  {
    path:'/view-photos',
    element:<ViewPhotos/>
  },
  {
    path:'/contact',
    element:<Contact />
  },
  {
    path:'*',
    element:<NotFound />
  }
]);

